import css from "./Homepage.css";
import Image from "./img/solicon.png";


function Homepage(props) {
    return (

        <div>

            <section>
                <div class="hero">
                    <h1>Become a Web3 Programmer Today!</h1>
                    <p>Announcing the launch of The Web3 Portfolio Builder Bootcamp! This 12-week cohort-based bootcamp contains everything you need to build your smart contracts, web3 websites, and even dApps. Enrollment closes November 25th at Midnight, so enroll while you can with the button below! </p>
                    {props.account ? <p> Connected account: {props.account}</p> : <p> </p>}
                    <button class="header-cta"><a href="/Products">View Products</a></button>
                </div>
                <div>
                    <img className="HeroImage" src={Image} ></img>
                </div>
            </section>

            <script src="https://unpkg.com/boxicons@2.1.2/dist/boxicons.js"></script>
            <script src="myscripts.js"></script>
        </div>



    );
}

export default Homepage;
