import css from "./BoughtWeb3.css";
import Image from "./img/solicon.png";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import ABI from "./ABI.json";


const contractAddress = "0x67207a7D5D73978129168969f80bf42EdD5C96A7";

function binaryToDecimal(x) {
    const parsed = parseInt(x, 2);
    if (isNaN(parsed)) { return 0; }
    return parsed;
}
function decimalToHex(x) {
    var parsed = x;

    var hexstr = ""
    while (parsed > 0) {
        var value = parsed % 16
        hexstr = (
            value == 10 ? "A" : (
                value == 11 ? "B" : (
                    value == 12 ? "C" : (
                        value == 13 ? "D" : (
                            value == 14 ? "E" : (
                                value == 15 ? "F" : value)))))) + hexstr
        parsed = parseInt(parsed / 16)
    }

    return '0x' + hexstr;
}
function binaryToHex(x) {
    return decimalToHex(binaryToDecimal(x))
}




function hex2bin(hex) {
    var hex = hex.toString().replace("0x", "").toLowerCase();
    var out = "";
    for (var c of hex) {
        switch (c) {
            case '0': out += "0000"; break;
            case '1': out += "0001"; break;
            case '2': out += "0010"; break;
            case '3': out += "0011"; break;
            case '4': out += "0100"; break;
            case '5': out += "0101"; break;
            case '6': out += "0110"; break;
            case '7': out += "0111"; break;
            case '8': out += "1000"; break;
            case '9': out += "1001"; break;
            case 'a': out += "1010"; break;
            case 'b': out += "1011"; break;
            case 'c': out += "1100"; break;
            case 'd': out += "1101"; break;
            case 'e': out += "1110"; break;
            case 'f': out += "1111"; break;
            default: return "";
        }
    }


    var newout = out.slice(100, -15)

    return binaryToHex(newout);
}



function BoughtWeb3(props) {

    const [address, setAddress] = useState("0");


    const [bought, setBought] = useState(false);


    //see if they bouhght it

    const asdf2 = hex2bin(address);





    const getChain = async () => {
        console.log(window.ethereum.networkVersion);


        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const currentAddress = await provider.getSigner().getAddress();
        setAddress(currentAddress);

        checkBought();

    }

    const checkBought = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const currentAddress = await provider.getSigner().getAddress();
        const contract = new ethers.Contract(contractAddress, ABI, signer);

        const checkBought = await contract.bought(currentAddress);
        setBought(checkBought)




    }


    useEffect(() => {
        getChain();
    }, []);

    if (bought == true) {
        return (



            <div>

                <section>
                    <div class="hero">
                        <h1>You're a lifetime member of the Club!</h1>
                        <p>Please send an email to "Theodore@WhiteboardCrypto.com" asking to be enrolled manually. Please supply your current wallet address, as well as this coupon: ""{address}0x{asdf2}"</p>



                    </div>

                </section>


            </div>



        );
    } else {
        return (
            <div>
                <h1 className="Switch"> You don't own this product on this chain. </h1>
                <br></br>
                <br></br>
                <br></br>
                <p className="Switch"> If you <i>just purchased</i>, the blockchain may still be processing your transaction. </p>
                <p className="Switch"> Try reloading if so...</p>
                <br></br>
                <button class="header-cta"><a onClick="window.location.reload();" href="">Reload</a></button>
            </div>


        );
    }


}

export default BoughtWeb3;
