import css from "./About.css";
import { useState,  } from "react";
import { ethers } from "ethers";






function About(props) {






  
        return (
            <div className="About">
                
                <h1 className="Switch">About</h1>
                
                <p className="Switch"> This is a payment application that allows you to purchase products and services for sale from the WhiteboardCrypto library.</p>
                <p className="Switch"> Please take note that this application is still in beta and is provided on an "as is" and "as available" basis. GrandpasToolbox.com does not give any warranties and will not be liable for any loss, direct or indirect through continued use of this application.</p>
                <p className="Switch"> Feel free to purchase the test product named "Turtle Cat" for $1 to see how payment flow works. </p>
                <p className="Switch"> If, for any reason, you have a question about this application, find any bugs, or wish you suggest a new idea, feel free to contact "Theodore@WhiteboardCrypto.com" with any inquries!</p>
                
                <br></br>
                
            </div>

        );
    


}

export default About;
