
import './Card.css';
import { useState, useEffect} from 'react';
import { ethers } from "ethers";
import ABI from "./ABI.json";
import tokenABI from "./tokenABI.json";

function Card(props) {

    const [bought, setBought] = useState(0);

    const [price, setPrice] = useState(0);

    const [CanUSDT, setCanUSDT] = useState(false)
    const [CanUSDC, setCanUSDC] = useState(false)
    const [CanMatic, setCanMatic] = useState(false)



    const [imageURL, setImageURL] = useState(props.imageURL);
    const [desc, setDesc] = useState(props.description);

    const [productImage, setproductImage] = useState(<div class="buyIcon">
        <img class="image1" src={imageURL} />
        
    </div>)

    const USDCaddress = props.usdc;
    const USDTaddress = props.usdt;


    //can get props.address


    const alreadyBought = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const currentAddress = await provider.getSigner().getAddress();
        const contract = new ethers.Contract(props.address, ABI, signer);
        const checkBought =  await contract.bought(currentAddress)
        setBought(checkBought);

        const getPrice = await contract.price();
        setPrice("$" + getPrice.toString()/1000000)
    }


    const payWithUSDC = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const currentAddress = await provider.getSigner().getAddress();
        const contract = new ethers.Contract(props.address, ABI, signer);
        const price = Number(await contract.price());
        

        //ethers.utils.formatEther(price.toString()

        const USDCcontract = new ethers.Contract(USDCaddress, tokenABI, signer);


        //check that they have at least "PRICE" tokens
        const currentBalance = Number(await USDCcontract.balanceOf(currentAddress));
        
        if (currentBalance >= price) {
            const allowance = Number(await USDCcontract.allowance(currentAddress, props.address));

            const alreadyBought = await contract.bought(currentAddress);
            
            if (alreadyBought == false) {
                if (allowance  < price ) {
                    setDesc("Approving USDC, please check Metamask...")
                    setproductImage(<div class="buyIcon">
                        <img class="image1" src={imageURL} />
                        <img class="image2" src="https://i.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.webp" />
                    </div>)



                    try {
                        const getAllowance = await USDCcontract.approve(props.address, price);

                        const allowrx = await getAllowance.wait()

                        if (allowrx.confirmations) {
                            setDesc("Purchasing with USDC, please check Metamask...")
                            setproductImage(<div class="buyIcon">
                                <img class="image1" src={imageURL} />
                                <img class="image2" src="https://i.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.webp" />
                            </div>)

                            try {
                                const tryToBuy = await contract.payUSDC();


                                const receipt = await tryToBuy.wait()

                                if (receipt.confirmations) {
                                    
                                    await new Promise(resolve => setTimeout(resolve, 2000));
                                    window.location.href = props.URL
                                }
                            } catch (error) {
                                setDesc(props.description)
                                setproductImage(<div class="buyIcon">
                                    <img class="image1" src={imageURL} />

                                </div>)
                            }
                            
                        }
                    } catch (error) {
                        setDesc(props.description)
                        setproductImage(<div class="buyIcon">
                            <img class="image1" src={imageURL} />

                        </div>)
                    }


                    
                } else {
                    setDesc("Purchasing with USDC, please check Metamask...")
                    setproductImage(<div class="buyIcon">
                        <img class="image1" src={imageURL} />
                        <img class="image2" src="https://i.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.webp" />
                    </div>)

                    const tryToBuy = await contract.payUSDC();


                    const receipt = await tryToBuy.wait()

                    if (receipt.confirmations) {
                        
                        await new Promise(resolve => setTimeout(resolve, 2000));
                        window.location.href = props.URL
                    }
                }



                

            }

        } else {
            console.log("You don't have enough USDC tokens")
        }


    }
    



    const checkTokens = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const currentAddress = await provider.getSigner().getAddress();
        const contract = new ethers.Contract(props.address, ABI, signer);

        const USDCcontract = new ethers.Contract(USDCaddress, tokenABI, signer);
        const USDTcontract = new ethers.Contract(USDTaddress, tokenABI, signer);

        const price = await contract.price();
        const balance = await provider.getBalance(currentAddress);
        const usdcbalance = await USDCcontract.balanceOf(currentAddress);
        const usdtbalance = await USDTcontract.balanceOf(currentAddress);

        const priceineth = await contract.getPriceInETH();

        

        
        //await new Promise(resolve => setTimeout(resolve, 1200));
        
        if (Number(usdtbalance) >= Number(price)) setCanUSDT(true)
        if (Number(usdcbalance) >= Number(price)) setCanUSDC(true)
        if (Number(balance) >= Number(priceineth)) setCanMatic(true);

        //await new Promise(resolve => setTimeout(resolve, 4200));
        
       
    
        

        
    }




    const payWithUSDT = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const currentAddress = await provider.getSigner().getAddress();
        const contract = new ethers.Contract(props.address, ABI, signer);
        const price = Number(await contract.price());
        
       

        const USDCcontract = new ethers.Contract(USDTaddress, tokenABI, signer);


        //check that they have at least "PRICE" tokens
        const currentBalance = Number(await USDCcontract.balanceOf(currentAddress));
        
        if (currentBalance >= price) {
            const allowance = Number(await USDCcontract.allowance(currentAddress, props.address));


            const alreadyBought = await contract.bought(currentAddress);

            if (alreadyBought == false) {
                if (allowance < price ) {
                    setDesc("Approving USDT, please check Metamask...")
                    setproductImage(<div class="buyIcon">
                        <img class="image1" src={imageURL} />
                        <img class="image2" src="https://i.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.webp" />
                    </div>)

                    try {
                        const getAllowance = await USDCcontract.approve(props.address, price);



                        const allowrx = await getAllowance.wait()

                        if (allowrx.confirmations) {
                            setDesc("Purchasing with USDT, please check Metamask...")
                            setproductImage(<div class="buyIcon">
                                <img class="image1" src={imageURL} />
                                <img class="image2" src="https://i.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.webp" />
                            </div>)


                            try {
                                const tryToBuy = await contract.payUSDT();


                                const receipt = await tryToBuy.wait()

                                if (receipt.confirmations) {
                                    
                                    await new Promise(resolve => setTimeout(resolve, 2000));
                                    window.location.href = props.URL
                                }
                            } catch (error) {
                                setDesc(props.description)
                                setproductImage(<div class="buyIcon">
                                    <img class="image1" src={imageURL} />

                                </div>)
                            }

                            



                        }
                    } catch (error) {
                        setDesc(props.description)
                        setproductImage(<div class="buyIcon">
                            <img class="image1" src={imageURL} />

                        </div>)
                    }
                    
                    
                    




                } else {
                    setDesc("Purchasing with USDT, please check Metamask...")
                    setproductImage(<div class="buyIcon">
                        <img class="image1" src={imageURL} />
                        <img class="image2" src="https://i.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.webp" />
                    </div>)

                    const tryToBuy = await contract.payUSDT();


                    const receipt = await tryToBuy.wait()

                    if (receipt.confirmations) {
                        
                        await new Promise(resolve => setTimeout(resolve, 2000));
                        window.location.href =  props.URL 
                    }
                }



                

            }
        } else {
            console.log("You don't have enough USDT tokens.")
        }


    }



        //check USDC Allowance

        //approve USDC allowance

        //send USDC tokens

        //go to /BoughtWeb3


    



    const payWithMATIC = async () => {
        
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const currentAddress = await provider.getSigner().getAddress();
        const contract = new ethers.Contract(props.address, ABI, signer);

        const price = await contract.getPriceInETH();
        const balance = await provider.getBalance(currentAddress);
        
        if (Number(price) < Number(balance)) {
            const alreadyBought = await contract.bought(currentAddress);

            if (alreadyBought == false) {
                setDesc("Purchasing with Matic, please check Metamask...")
                setproductImage(<div class="buyIcon">
                    <img class="image1" src={imageURL} />
                    <img class="image2" src="https://i.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.webp" />
                </div>)

                try {
                    var tryToBuy = await contract.payETH({ value: price });
                    const receipt = await tryToBuy.wait()

                    if (receipt.confirmations) {
                        await new Promise(resolve => setTimeout(resolve, 2000));
                        window.location.href = props.URL
                    }
                } catch (error) {
                    setDesc(props.description)
                    setproductImage(<div class="buyIcon">
                        <img class="image1" src={imageURL} />
                        
                    </div>)
                }
                
                

                
            } else {
                console.log("Already Bought")
            }
        } else {
            console.log("You don't have enough MATIC")
         
        }
        
        


    }
    //pay with USDC
    //pay with USDT
    //pay with MATIC

    useEffect(() => {
        alreadyBought();
        checkTokens();
        
        
    }, []);


    useEffect(() => {
        

    }, [CanUSDC]);

  


    if (bought) {
        return (
            <div class="card" >
                

                <div class="card__image-container">
                    <img
                        src={props.imageURL}
                        width="400"
                    />

                </div>
                <div class="card__content">
                    <p class="card__title text--medium">
                        {props.name}
                    </p>
                    <div class="card__info">
                        <p class="text--medium">{props.description} </p>
                        {/* <p class="card__price text__price">
      {this.props.price != null ? this.props.price : "Enter"}</p>*/}
                    </div>
                    
                    <div className = "alreadyBought"><a href={props.URL} >
                        <p class="card__price text__price">
                            You own this!</p></a>
                    </div>

                </div>
            </div>
        );


        

    } else {



        return (
            
            
            <div class="card" >
                
                <div class="card__image-container">


                    {productImage}


                    

                </div>
                <div  class="card__content">
                    <p class="card__title text--medium">
                        {props.name}
                    </p>
                    <div class="card__info">
                        <p class="text--medium">{desc} </p>
                        {/* <p class="card__price text__price">
      {this.props.price != null ? this.props.price : "Enter"}</p>*/}
                    </div>
                    <div>
                        {CanUSDC ? (
                            <img onClick={() => payWithUSDC()} class="buyIcon" src="https://cryptologos.cc/logos/usd-coin-usdc-logo.png" ></img>
                        ) : (
                                <img class="buyIconGrey" src="https://cryptologos.cc/logos/usd-coin-usdc-logo.png" ></img>
             
                        )}

                        {CanUSDT ? (
                            <img onClick={() => payWithUSDT()} class="buyIcon" src="https://cdn-icons-png.flaticon.com/512/825/825508.png" ></img>
                        ) : (
                                <img class="buyIconGrey" src="https://cdn-icons-png.flaticon.com/512/825/825508.png" ></img>
                        )}

                        {CanMatic ? (
                            <img onClick={() => payWithMATIC()} class="buyIcon" src="https://cdn.iconscout.com/icon/free/png-256/polygon-token-4086724-3379854.png" ></img>
                        ) : (
                                <img class="buyIconGrey" src="https://cdn.iconscout.com/icon/free/png-256/polygon-token-4086724-3379854.png" ></img>
                        )}
                         </div>
                    <div>
                        <p class="card__price text__price">
                            {price}</p>
                    </div>

                </div>
                </div>
                
        );
    }
    
}

export default Card;








