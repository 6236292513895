import css from "./BoughtAI.css";

import { useState, useEffect } from "react";
import { ethers } from "ethers";
import ABI from "./ABI.json";
import tc1 from "./img/tc1.png"
import tc2 from "./img/tc2.png"
import tc3 from "./img/tc3.png"
import tc4 from "./img/tc4.png"
import tc5 from "./img/tc5.png"
import tc6 from "./img/tc6.png"

const contractAddress = "0x1D000a56E354912fbf605723E450E8EF5fFB2c86";





function BoughtWeb3(props) {

    const [address, setAddress] = useState("0");


    const [bought, setBought] = useState(false);


    //see if they bouhght it

    





    const getChain = async () => {
        


        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const currentAddress = await provider.getSigner().getAddress();
        setAddress(currentAddress);

        checkBought();

    }

    const checkBought = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const currentAddress = await provider.getSigner().getAddress();
        const contract = new ethers.Contract(contractAddress, ABI, signer);

        const checkBought = await contract.bought(currentAddress);
        setBought(checkBought)




    }


    useEffect(() => {
        getChain();
    }, []);

    if (bought == true) {
        return (



            <div>

                
                    <div class="hero">
                        <h1>Here are some turtle-cat images, generated by AI:</h1>
                    <p>If you want to create images like this, here's how you can do it:</p>
                    <p>1) Join this Discord server: <a href="https://discord.gg/midjourney">https://discord.gg/midjourney</a> </p>
                    <p>2) Create an image by starting with "/prompt " then pushing [tab]</p>
                    <p>3) Fill the prompt with what you want the AI to create</p>
                    <p>4) Wait 60 seconds and view the 4 images! </p>

                    <img src={tc1} />
                    <img src={tc2} />
                    <img src={tc3} />
                    <img src={tc4} />
                    <img src={tc5} />
                    <img src={tc6} />


                    </div>

                


            </div>



        );
    } else {
        return (
            <div class="hero">
                <h1 className="Switch"> You don't own this product on this chain. </h1>
                <br></br>
                <br></br>
                <br></br>
                <p className="Switch"> If you <i>just purchased</i>, the blockchain may still be processing your transaction. </p>
                <p className="Switch"> Try reloading if so...</p>
                <br></br>
                <button class="header-cta"><a onClick="window.location.reload();" href="">Reload</a></button>
            </div>


        );
    }


}

export default BoughtWeb3;
