import logo from './logo.svg';
import './App.css';
import Products from "./Products.js";
import Homepage from "./Homepage.js";
import { BrowserRouter as Router, Switch, Route, Routes } from 'react-router-dom';
import BoughtWeb3 from "./BoughtWeb3.js";
import BoughtWBCC from "./BoughtWBCC.js";
import About from "./About.js";
import BoughtAI from "./BoughtAI.js";

function App() {

    document.title = "WhiteboardCrypto Apps";
    return (
      
    <div className="App">
            
          <header>
              <nav>
                  <div class="logo">
                      <h1><a href="/">Grandpa's Toolbox</a></h1>
                  </div>
                  <ul className="navheader">
                        <li><a href="./">Home</a></li>
                        <li><a href="/About">About</a></li>
                      <li class="nav-cta"><a href="/Products">Products</a></li>



                  </ul>
                  <box-icon name='menu-alt-right' color="whitesmoke" id="menu"></box-icon>
              </nav>
          </header>

          <Router>
              <Routes>
                  <Route path="/" element={<Homepage />} />
                  <Route path="/Products" element={<Products />} />
                  <Route path="/BoughtWeb3" element={<BoughtWeb3 />} />
                    <Route path="/BoughtWBCC" element={<BoughtWBCC />} />
                    <Route path="/BoughtAI" element={<BoughtAI />} />
                  <Route path="/About" element={<About />} />

              </Routes>

          </Router>
         
          


    </div>
  );
}

export default App;
