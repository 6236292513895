import './Products.css';
import Card from "./Card.js";
import Logo from "./img/web3bootcamp.png";
import Logo2 from "./img/wbcc.png"
import Logo3 from "./img/AI.png";
import { ethers } from "ethers";
import { useState, useEffect } from 'react';










const reLoad = () => {
    window.location.reload();
}

if (window.ethereum && window.ethereum.isMetaMask) {
    window.ethereum.on('chainChanged', reLoad);
    window.ethereum.on('accountsChanged', reLoad)
}


//check for metamask

//check for change Chains
//check for change accounts

//make sure chain = polygon





function Products() {

    const [chain, setChain] = useState(0);
    const [curaddress, setCuraddress] = useState("0x");
    const getChain = async () => {
        console.log(window.ethereum.networkVersion);


        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const connect = await provider.send("eth_requestAccounts");
        const signer = provider.getSigner();




        const network = await provider.getNetwork();
        const chainId = network.chainId;
        setChain(chainId);
        ///console.log("y" + chainId)
        const thisAccount = await provider.getSigner().getAddress();
        setCuraddress(thisAccount);
        console.log("Account: " + thisAccount)

    }


    
   

    const changePolygon = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const connect = await provider.send("eth_requestAccounts");

        if (window.ethereum) {
            
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x89" }],
                });
            
        } 
    }
    


    useEffect(() => {
        getChain();

        
    }, []);

    //if chain != 137, ask to switch to Polygon

    if (chain !== 137) {
        return (
            
            <div>
                <h1 className="SwitchCh"> Please Switch to Polygon </h1>
                <button class="header-cta"><a onClick={changePolygon} href="#">Switch to Polygon</a></button>
            </div>
            );
    } else {
        return (
            <div>
                <div>

                    {/*<p id="acc" > Connected Account: {curaddress} </p>*/}
                </div>
                <p style={{ color: "white" }}>If the tokens are greyed out, it means you do not own enough to purchase!</p>
                <main>
                    <section class="cards">
                        <Card name="Web3 Bootcamp" address="0xF2C3c11d654304a6012e36303a4AE34D14F0B741" usdc="0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" usdt="0xc2132D05D31c914a87C6611C10748AEb04B58e8F" link="Web3Lessons" URL="/BoughtWeb3" imageURL={Logo} description="A web3 bootcamp designed to teach you everything needed to build your own portfolio of dapps" />
                        <Card name="WBC Club" address="0x67207a7D5D73978129168969f80bf42EdD5C96A7" usdc="0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" usdt="0xc2132D05D31c914a87C6611C10748AEb04B58e8F" link="Web3Lessons" URL="/BoughtWBCC" imageURL={Logo2} description="A complete guide to take advantage of blockchain technology, find hidden crypto projects, and properly use DeFi tools to start earning money!" />
                        <Card name="Turtle Cats" address="0x1D000a56E354912fbf605723E450E8EF5fFB2c86" usdc="0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" usdt="0xc2132D05D31c914a87C6611C10748AEb04B58e8F" link="Web3Lessons" URL="/BoughtAI" imageURL={Logo3} description="This is a test product, upon payment you'll be able to view a bunch of AI-generated 'turtle-cats' (and learn how to create them yourself)" />



                        {/* <Card name="Web3 Bootcamp" address="0xdc1A8951E969864b9C9E82e9e3659dFE7d5e7D36" usdc="0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" usdt= "0xc2132D05D31c914a87C6611C10748AEb04B58e8F" link="Web3Lessons" URL = "/HeyVala" imageURL={Logo} description="A web3 bootcamp designed to teach you everything needed to build your own portfolio of dapps" />
                        <Card name="WBC Club" address="0xD18cfe60A4c1CE9D678Fb9b01B4eC670332e83cD" usdc="0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" usdt="0xc2132D05D31c914a87C6611C10748AEb04B58e8F" link="Web3Lessons" URL="/HeyVala2" imageURL={Logo2} description="A complete guide to take advantage of blockchain technology, find hidden crypto projects, and properly use DeFi tools to start earning money!" />
                        */}

                    </section>

                </main>
                 

            </div>
        );
    }



}

export default Products;
